<template>
  <v-container fluid>
    <PageNavigation :items="items" />

    <v-row>
      <v-col cols="12">
        <base-material-card
          :title="$vuetify.lang.t('$vuetify.goodsInfo')"
          icon="mdi-apple-finder"
        >
          <v-row no-gutters>
            <v-col cols="12" lg="3" md="6">
              <v-text-field
                :class="$vuetify.breakpoint.mdAndDown ? 'px-4' : ''"
                :label="$vuetify.lang.t('$vuetify.search')"
                v-model="search"
                append-icon="mdi-magnify"
                clearable
                hint="Click search to confirm"
                @click:clear="handleClear"
                @click:append="handleSearch"
              />
            </v-col>

            <v-col cols="12" lg="3" md="6">
              <v-select
                class="mx-4"
                :items="typeArr"
                label="Categories"
                prepend-inner-icon="mdi-apple-finder"
                clearable
                return-object
                @change="(item) => handleCategory(item)"
              />
            </v-col>

            <v-spacer />

            <v-col
              v-if="$vuetify.breakpoint.lgAndUp"
              cols="12"
              lg="4"
              md="12"
              class="d-flex justify-end"
              align-self="center"
            >
              <v-btn
                color="green"
                elevation="2"
                @click="handlePrintDialog"
                v-if="permissionCheck('add')"
              >
                <v-icon left>mdi-printer</v-icon>
                {{ $vuetify.lang.t("$vuetify.print") }}
              </v-btn>

              <v-btn
                v-if="permissionCheck('add')"
                color="green"
                elevation="2"
                @click.native="$router.push('/products/type')"
              >
                <v-icon left>mdi-plus</v-icon>
                {{ $vuetify.lang.t("$vuetify.createCategory") }}
              </v-btn>

              <v-btn
                v-if="permissionCheck('add')"
                color="green"
                elevation="2"
                @click="handleDialog"
              >
                <v-icon left>mdi-account-plus</v-icon>
                {{ $vuetify.lang.t("$vuetify.add") }}
              </v-btn>
            </v-col>

            <v-col
              v-else
              cols="12"
              lg="4"
              md="12"
              class="d-flex justify-end"
              align-self="center"
            >
              <v-btn
                color="green"
                elevation="2"
                @click="handlePrintDialog"
                v-if="permissionCheck('add')"
              >
                <v-icon>mdi-printer</v-icon>
              </v-btn>

              <v-btn
                v-if="permissionCheck('add')"
                color="green"
                elevation="2"
                @click.native="$router.push('/products/type')"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>

              <v-btn
                v-if="permissionCheck('add')"
                color="green"
                elevation="2"
                @click="handleDialog"
              >
                <v-icon>mdi-account-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <ProductTable
            :search="search"
            :headers="headers"
            :datas="datas"
            :data_table_options.sync="data_table_options"
            @go2Page="handleGo2Page"
            @pageChange="handlePageChange"
            @edititem="handleEditDialog"
            @deleteitem="handleDeleteDialog"
          />
        </base-material-card>
      </v-col>
    </v-row>

    <ProductCRUD
      :dialog.sync="dialog"
      :myObj="obj"
      :imageList="imageList"
      @handleData="handleProductConfirm"
    />

    <PrintTable :dialogPrint.sync="dialogPrint" />

    <DialogDelete
      :dialogDelete.sync="dialogDelete"
      :deleteObj="obj"
      @handleDelete="handleDeleteConfirm"
    />
  </v-container>
</template>

<script>
//TODO missing batch upload

import {
  goodsTypeList,
  goodsTypeUpsert,
  goodsTypeDel,
  goodsList,
  goodsDel,
  goodsUpsert,
  goodsParamTemplateList,
  listCodetypes,
} from "@/api/goods";
import { sellList } from "@/api/basicdata";
import serverConfig from "@/utils/serverConfig";

const clone = (obj) => Object.assign({}, obj);
const renameKey = (object, key, newKey) => {
  const clonedObj = clone(object);
  const targetKey = clonedObj[key];

  delete clonedObj[key];
  clonedObj[newKey] = targetKey;
  return clonedObj;
};

function FilterSubNode(mainNode, NodeList, list) {
  if (NodeList != undefined) {
    for (let i = 0; i < NodeList.length; i++) {
      list.push({
        text: mainNode + NodeList[i].name,
        value: NodeList[i].code,
      });
      if (NodeList[i].items != null) {
        FilterSubNode(
          mainNode + NodeList[i].name + " / ",
          NodeList[i].items,
          list
        );
      }
    }
  }
  return list;
}

function newObj() {
  return {
    id: "",
    type_code: "",
    code_type: "",
    code: "",
    name: "",
    unit: "",
    en_name: "",
    note: "",
    param_template: "",
    internal_param_id: "",
    internal_param_str: "",
    edit: false,
    other_code: "",
    other_uid: "",
    class: "",
    style: "",
    packings: "",
    products: "",
    structure: "",
    production_craft: "",
    personal_craft: "",
    thickness: "",
    size: "",
    image: [],
    attach: "",
    diameter: "",
    param_data: {
      1001: "",
      1002: "",
      1003: "",
      1004: "",
      1005: "",
    },
  };
}

function str2ArrayImg(item) {
  if (item.image.includes(",")) {
    return item.image.split(",")[0];
  } else {
    const temp = [];

    temp.push(item.image);

    return temp[0];
  }
}

const newType = {
  id: "",
  parent: "",
  name: "",
  order: "",
  template_id: "",
  sell_company: [], // 销售主体
};

const newSearch = () => {
  return {
    type_code: "",
    skip: 0,
    limit: 10,
    key: "",
  };
};

const newTableOption = () => {
  return {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    totalItems: 0,
    footerProps: {
      itemsPerPageOptions: [10, 20, 30, 40, 50],
    },
  };
};

export default {
  name: "productList",
  components: {
    TableImg: () => import("@/components/tableImg"),
    ProductTable: () => import("@/components/generalTable"),
    ProductCRUD: () => import("@/components/product/productCRUD"),
    DialogDelete: () => import("@/components/deleteDialog"),
    PageNavigation: () => import("@/components/pageNavigation"),
    PrintTable: () => import("@/components/product/printTable"),
  },
  data() {
    return {
      page: 1,
      dialog: false,
      dialogDelete: false,
      dialogPrint: false,
      search: "",
      typeArr: [],
      datas: [],
      imageList: [],
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.image"),
          value: "image",
          width: "20",
          align: "center",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.goodsCode"),
          value: "other_code",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.goodsName"),
          value: "name",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.type"),
          value: "type_name",
          width: "100",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.note"),
          value: "note",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.opt"),
          value: "actions",
          sortable: false,
          align: "center",
          width: "80",
        },
      ],
      items: [
        { text: this.$vuetify.lang.t("$vuetify.home") },
        { text: this.$vuetify.lang.t("$vuetify.goodsInfo") },
      ],
      obj: newObj(),
      searchForm: newSearch(),
      data_table_options: newTableOption(),
    };
  },
  methods: {
    getProduct() {
      goodsList(this.searchForm)
        .then((res) => {
          if (res.items != null) {
            this.datas = [...res.items];

            this.data_table_options.totalItems =
              res.total <= 10
                ? 1
                : Math.round(
                    Math.floor(res.total / 10) + (res.total % 10 == 0 ? 0 : 1)
                  );

            this.datas.forEach((p) => {
              if (p.image.includes(",")) {
                p.image = p.image.split(",");
              } else {
                const temp = [];

                temp.push(p.image);

                p.image = temp;
              }
            });

            for (var i = 0; i < this.datas.length; i++) {
              this.datas[i].itemNo = i + 1;
            }
          } else {
            this.$toast.info("No Data Found", { timeout: 1500 });
            this.datas = [];
            this.data_table_options = newTableOption();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getType() {
      goodsTypeList()
        .then((res) => {
          this.typeArr = FilterSubNode("", res.items, []);

          this.typeArr.push({ text: "All", value: "" });
        })
        .catch((err) => {
          console.log("Product Type Error", err);
        });
    },
    handleDialog() {
      this.dialog = true;
      this.obj = newObj();
      this.imageList = [];
    },
    handleEditDialog(obj) {
      if (this.permissionCheck("modify")) {
        this.dialog = true;
        this.obj = obj;

        if (obj.image == "") {
          obj.image = [];
        } else {
          this.imageList = str2ArrayImg(obj);
        }
      }
    },
    handleDeleteDialog(obj) {
      this.dialogDelete = true;
      this.obj = obj;
    },
    handleProductConfirm(obj) {
      this.dialog = false;

      goodsUpsert(obj)
        .then(() => {
          this.getProduct();
        })
        .catch((err) => console.log("Product Error", err));
    },
    handleDeleteConfirm(obj) {
      this.dialogDelete = false;

      goodsDel(obj.id)
        .then(() => {
          this.getProduct();
        })
        .catch((err) => console.log("Product Delete", err));
    },
    handleSearch() {
      if (this.search != null) {
        this.searchForm.key = this.search;
      }

      this.getProduct();
    },
    handleClear() {
      this.searchForm.key = "";

      this.getProduct();
    },
    handleGo2Page(item) {
      if (item != "" || item != 0) {
        this.data_table_options.page = item;
      } else {
        this.data_table_options.page = 1;
      }

      this.getProduct();
    },
    handlePageChange(item) {
      this.searchForm.skip = item.itemsPerPage * (item.page - 1);

      this.getProduct();
    },
    handleCategory(item) {
      if (item != null) {
        this.searchForm.type_code = item.value;
      } else {
        this.searchForm.type_code = "";
      }

      this.getProduct();
    },
    handlePrintDialog() {
      this.dialogPrint = true;
    },
    handlePrint() {
      window.print();
    },
  },
  mounted() {
    this.getType();

    this.getProduct();
  },
};
</script>